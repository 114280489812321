<template>
  <BaseCard
    :resource="$data.$constants.RESOURCES.RESOURCE_PROFILE"
    actions-visible
    :delete-action-visible="false"
    :footer-visible="false"
    @edit-icon-click="$router.push({ name: 'editProfile' })"
  >
    <template #title>
      <b-avatar
        size="lg"
        :src="avatar"
        class="mr-1"
      />
      <span class="text-capitalize text-dark">{{ userFullName }}</span>
    </template>
    <hr class="m-0">
    <ProfilePanel :profile="userProfile" />
  </BaseCard>
</template>

<script>
import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import { mapGetters } from 'vuex'

export default {
  components: { BaseCard, ProfilePanel },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
    }),
    userFullName() {
      if (!this.user) {
        return ''
      }

      return `${this.user.name} ${this.user.surname || ''}`
    },
    avatar() {
      return this.user?.avatar || ''
    },
    rol() {
      if (!this.user || !this.user.roles || this.user.roles.length === 0) {
        return 'Sin rol'
      }

      return this.user.roles[0].display_name
    },
    userProfile() {
      return [
        { label: 'Correo electrónico', value: this.user?.email || 'Sin correo electrónico' },
        { label: 'Rol', value: this.rol },
        { label: 'Cargo', value: this.user?.job || 'Sin cargo' },
        { label: 'Teléfono', value: this.user?.phone || 'Sin teléfono' },
        { label: 'Departamento', value: this.user?.department || 'Sin departamento' },
      ]
    },
  },
}
</script>

<style scoped>
</style>
